<template>
  <a-modal
    v-model:visible="showModal"
    centered
    :title="`${recordToEdit ? 'Update' : 'Add'} Subscription`"
    :ok-text="`${recordToEdit ? 'Update' : 'Create'}`"
    cancel-text="Cancel"
    :confirm-loading="isCreating"
    @ok="handleSubmit"
    @cancel="onModalClose"
  >
    <a-form
      ref="formRef"
      :model="formState"
      layout="vertical"
      name="form_in_modal"
    >
      <a-form-item
        label="Event Type"
        name="event_type"
        :rules="[{ required: true, message: 'Please select event type!' }]"
      >
        <a-select
          v-model:value="formState.event_type"
          :options="eventTypeOptions"
          show-search
          placeholder="Select event type"
        />
      </a-form-item>
      <a-form-item
        label="Organization"
        name="Organization"
        :rules="[{ required: true, message: 'Please select organization!' }]"
      >
        <a-select
          v-model:value="formState.Organization"
          :loading="isFetchingOrgs"
          :options="organizations"
          show-search
          placeholder="Select organization"
        />
      </a-form-item>
      <a-form-item
        label="Recipient(s)"
        name="recipients"
        :rules="[{ required: true, message: 'Please enter recipients!' }]"
      >
        <a-select
          v-model:value="formState.recipients"
          mode="tags"
          style="width: 100%"
          placeholder="Add recipients"
        />
      </a-form-item>
    </a-form>
  </a-modal>

  <a-row class="p-4" :gutter="[16, 16]">
    <a-col span="24" class="d-flex">
      <a-select
        v-model:value="searchByOrg"
        class="w-50"
        placeholder="Search by organization"
        show-search
        allow-clear
        :options="organizations"
      >
      </a-select>

      <a-button class="ml-auto" type="primary" @click="showModal = true">
        Add New Subscription
      </a-button>
    </a-col>
    <a-col span="24">
      <a-table
        :columns="columns"
        :data-source="eventNotifications"
        bordered
        :loading="isLoading"
        :pagination="pagination"
        @change="handleTableChange"
      >
        <template #title>
          <a-typography-title :level="5">
            Events Subscription:
            <a-tag color="blue" class="ml-2">
              {{ eventNotifications.length }}
            </a-tag>
          </a-typography-title>
        </template>
        <template #bodyCell="{ record, column }">
          <span v-if="column.dataIndex === 'recipients'">
            <a-tag
              v-for="recipient in record.recipients"
              :key="recipient"
              color="processing"
            >
              {{ recipient }}
            </a-tag>
          </span>
          <span v-if="column.dataIndex === 'actions'">
            <a-space class="actions">
              <a-button class="bg-warning" @click="handleEdit(record)">
                Edit
              </a-button>
              <a-popconfirm
                title="Are you sure? you want to delete the subscription?"
                ok-text="Yes"
                cancel-text="No"
                @confirm="deleteEventNotification(record.id)"
              >
                <a-button type="primary" danger>Delete</a-button>
              </a-popconfirm>
            </a-space>
          </span>
        </template>
      </a-table>
    </a-col>
  </a-row>
</template>
<script setup>
import { onMounted, ref, watch } from 'vue';
import EventNotificationService from 'src/services/eventNotification';
import { columns, eventTypeOptions } from './config';
import { useStore } from 'vuex';
import { computed } from 'vue';
import { reactive } from 'vue';

const store = useStore();

const searchByOrg = ref(null);
const isLoading = ref(false);
const showModal = ref(false);
const isCreating = ref(false);
const totalRecords = ref(0);
const eventNotifications = ref([]);
const formState = ref({
  event_type: '',
  Organization: '',
  recipients: [],
});
const formRef = ref();
const recordToEdit = ref(null);
const paginationState = reactive({
  pageSize: 10,
  current: 1,
});

const isFetchingOrgs = computed(() => store.getters.isLoadingOrganizations);
const organizations = computed(() =>
  store.getters.organizations.map((el) => ({
    label: el.Org_name,
    value: el.Org_name,
  }))
);

watch(searchByOrg, () => fetchEventNotifications());

const pagination = computed(() => ({
  total: totalRecords.value,
  current: paginationState.current,
  pageSize: paginationState.pageSize,
  showSizeChanger: false,
  position: ['bottomCenter'],
}));

const onModalClose = () => {
  formState.value = {};
  recordToEdit.value = null;
};

const fetchEventNotifications = async () => {
  isLoading.value = true;
  const [error, data] = await EventNotificationService.fetchEventNotifications({
    page: paginationState.current,
    Organization: searchByOrg.value,
  });

  isLoading.value = false;
  if (error) {
    console.log('Error while fetch records', error);
    return;
  }
  totalRecords.value = data.count;
  eventNotifications.value = data.results;
};

function handleTableChange(page) {
  console.log(page);
  if (page.current !== Number(paginationState.current)) {
    paginationState.current = page.current;
    console.log('set');
  }
  fetchEventNotifications();
}

const createEventNotification = async (values) => {
  isCreating.value = true;
  const [error] = await EventNotificationService.createEventNotification(
    values
  );

  isCreating.value = false;
  showModal.value = false;
  if (error) {
    console.log('Error while creating the record:', error);
    return;
  }
  formRef.value.resetFields();
  fetchEventNotifications();
};

const handleEdit = ({ id, event_type, Organization, recipients }) => {
  recordToEdit.value = id;
  formState.value = {
    event_type,
    Organization,
    recipients,
  };
  showModal.value = true;
};

const updateEventNotification = async (values) => {
  isCreating.value = true;

  const [error] = await EventNotificationService.updateEventNotification(
    recordToEdit.value,
    values
  );
  isCreating.value = false;
  showModal.value = false;
  if (error) {
    console.log('Error while updating the record:', error);
    return;
  }
  formRef.value.resetFields();
  fetchEventNotifications();
  onModalClose();
};

const deleteEventNotification = async (id) => {
  isLoading.value = true;
  const [error] = await EventNotificationService.deleteEventNotification(id);
  isLoading.value = false;

  if (error) {
    console.log('Error while deleting the record:', error);
    return;
  }

  fetchEventNotifications();
};

const handleSubmit = () => {
  formRef.value
    .validateFields()
    .then((values) => {
      if (recordToEdit.value) updateEventNotification(values);
      else createEventNotification(values);
    })
    .catch((info) => {
      console.log('Validate Failed:', info);
    });
};

onMounted(() => {
  fetchEventNotifications();
  store.dispatch('getOrganizations');
});
</script>
<style lang=""></style>
